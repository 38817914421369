/**
 * Blog Page
 * @namespace BlogArticlePage
 */
import React from "react";
import renderHTML from 'react-render-html';
import config from "../../config/main.config";
import browserHistory from "../../helper/browserHistory";
import CardDateComponent from "../../components/card/CardDateComponent";
import moment from "moment";
import CardDownloadsComponent from "../../components/card/CardDownloadsComponent";
import { Helmet } from "react-helmet";

import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";

class BlogArticlePage extends React.Component {

    /**
     * Sets state all values for the article
     * @constructor
     * @memberOf BlogArticlePage
     */
    constructor(props) {
        super(props);

        if (props.staticContext && props.staticContext.hasOwnProperty("header")) {
            let article = props.staticContext;
            this.state = {
                header: article.header,
                author: article.author,
                date: article.date,
                text: article.text,
                description: article.description,
                thumbnail: article.thumbnail,
                articleDates: article.articleDates,
                articleDownloads: article.articleDownloads,
                articleImages: article.images,
                createdAt: article.createdAt
            }
        } else {
            this.state = {
                header: "",
                author: "",
                date: "",
                text: "",
                description: "",
                thumbnail: "",
                articleDates: [],
                articleDownloads: [],
                articleImages: [],
                createdAt: ""
            };
        }


    }

    /**
     * @memberOf BlogArticlePage
     */
    componentDidMount() {
        let id = this.props.match.params.id;
   /* just in case this is needed in the future:
        new RegExp(/\/preisgekroent[0-9]{2}/g).test(this.props.match.path)*/

        if (this.props.match.path.endsWith("/ball")) {
            id = "e16550a3-bc3c-452d-abcb-6b8b9a975e6e";
        }
        if (this.props.match.path.endsWith("/preisgekroent24")) {
            id = "baee8363-ee7d-4023-a409-78cec5362bed";
        }
        if (this.props.match.path.endsWith("/wissensfabrik")) {
            id = "d41b4715-6fc5-487c-a680-9abb3ae3ba75";
        }

        fetch(config.BASE_URL + 'blog/article/' + id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'type': 'seminars'
            }
        }).then((resp) => resp.json())
            .then(article => {
                if (article.hasOwnProperty("statusCode")) {
                    let head = "Es ist ein Fehler unterlaufen";
                    let text;
                    if (article.statusCode === "201") {
                        //Not found
                        text = "<p>Dieser Artikel ist nicht mehr Verfügbar</p>";
                    } else {
                        //Not published
                        text = "<p>Dieser Artikel ist momentan nicht Verfügbar</p>";
                    }

                    this.setState({
                        ...this.state,
                        header: head,
                        text: text
                    });
                } else {
                    this.setState({
                        ...this.state,
                        header: article.header,
                        author: article.author,
                        date: article.date,
                        text: article.text,
                        description: article.description,
                        thumbnail: article.thumbnail,
                        articleDates: article.articleDates,
                        articleDownloads: article.articleDownloads,
                        articleImages: article.images
                    });
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
        window.scrollTo(0, 0);
    }


    getColorForDate(index) {
        let mod = index % 4;
        switch (mod) {
            case 0:
                return "blue";
            case 1:
                return "orange";
            case 2:
                return "light-blue";
            case 3:
                return "green";
            default:
                break;
        }
    }

    /**
     * Returns Blog page
     * @return {XML} html of this component
     * @memberOf BlogArticlePage
     */
    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        let sortedDates = this.state.articleDates.sort(function (a, b) {
            let aM = moment(a.date, "YYYY-MM-DD");
            let bM = moment(b.date, "YYYY-MM-DD");
            if (aM.isAfter(bM)) {
                return 1;
            }
            if (bM.isAfter(aM)) {
                return -1;
            }
            return 0;
        });


        const showDates = sortedDates.map((date, index) =>
            <CardDateComponent
                text={date.text}
                title={date.title}
                date={date.date}
                color={this.getColorForDate(index)}
                key={index}
            />
        );

        const showDownloads = this.state.articleDownloads.map((download, index) =>
            <CardDownloadsComponent
                key={index}
                title={download.title}
                link={download.link}
            />
        );

        const showImages = this.state.articleImages.map((image, index) =>
            <Grid.Column computer={5} tablet={8} mobile={8}
                key={index}>
                <Image
                    fluid
                    src={image.link}
                />
            </Grid.Column>
        );

        let style = { backgroundImage: "linear-gradient(225deg, rgba(0, 78, 132, 0.24), #004e84), url('" + this.state.thumbnail + "')" };
        return (
            <Grid itemScope itemType={"https://schema.org/BlogPosting"}>
                <Helmet>
                    <title>{this.state.header}</title>
                    <meta name="description"
                        content={this.state.description} />
                    <meta name="robots" content="index,follow" />

                    <meta property="og:site_name" content="Bürgerstiftung Kreis Ravesnburg" />
                    <meta property="og:title" content={this.state.header} />
                    <meta property="og:description" content={this.state.description} />
                    <meta property="og:image" itemProp="image" content={this.state.thumbnail} />
                    <meta property="og:type" content="website" />
                    <meta property="og:updated_time" content={this.state.date} />
                </Helmet>
                <Grid.Row
                    centered
                    className={"page-title-image"}
                    itemProp="mainEntityOfPage"
                    style={style}>
                    <Grid.Column width={16} textAlign={"center"}>
                        <Image centered
                            src={Seperator}
                            size={"tiny"}
                        />
                        <h2 itemProp="name">{this.state.header}</h2>
                        <meta itemProp="headline"
                            content={this.state.description.length > 100 ?
                                this.state.description.substring(0, 100) + "..." :
                                this.state.description
                            } />
                        <h3>{this.state.description}</h3>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'} />
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"} style={{ paddingBottom: "60px" }}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/current");
                                this.setState({});
                            }}>
                                AKTUELLES
                            </Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section className={"active-breadcrumb"}>
                                {this.state.header.toUpperCase()}
                            </Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column mobile={16} tablet={14} computer={12}>
                        {this.state.description}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered width={16}>
                    <Grid.Column mobile={16} tablet={14} computer={12} itemProp="articleBody">
                        {renderHTML(this.state.text)}
                    </Grid.Column>
                </Grid.Row>
                {showDates}
                <Grid.Row centered>
                    {showImages}
                </Grid.Row>
                {this.state.articleDownloads.length > 0 ?
                    <Grid.Row centered>
                        <Grid.Column width={16} textAlign={"center"}>
                            <Image centered
                                src={Seperator}
                                size={"tiny"}
                            />
                            <h2>Weitere Informationen & Downloads</h2>
                        </Grid.Column>
                    </Grid.Row>

                    : null
                }
                {showDownloads}
                <Grid.Row style={{ display: "none" }}>
                    <div
                        itemProp="image" itemScope itemType="https://schema.org/ImageObject"
                        className="image-container">
                        <img src={this.state.thumbnail}
                            width="530" height="300"
                            alt={this.state.header}
                            title={this.state.header} />
                        <meta itemProp="url"
                            content={this.state.thumbnail} />
                    </div>
                    <small>
                        <a itemProp="url"
                            href={config.PUBLIC_URL + "/blog-article/" + this.props.match.params.id}
                            title={this.state.header}>
                            <span>
                                {this.state.header}
                            </span>
                        </a>
                        <time itemProp="datePublished">{this.state.createdAt}</time>
                        <meta itemProp="dateModified" content={this.state.createdAt} />
                    </small>
                    <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                        <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                            <img src="https://www.buergerstiftung-kreis-rv.de/images/Logo.svg"
                                alt="Bürgerstiftung Kreis Ravesnburg" />
                            <meta itemProp="url"
                                content="https://www.buergerstiftung-kreis-rv.de/images/Logo.png" />
                            <meta itemProp="width" content="262" />
                            <meta itemProp="height" content="185" />
                        </div>
                        <meta itemProp="name" content="Bürgerstiftung Kreis Ravesnburg" />
                    </div>

                    <small>
                        <span itemProp="author" itemScope itemType="https://schema.org/Person">
                            <span itemProp="name">
                                <a href="https://www.buergerstiftung-kreis-rv.de/"
                                    itemProp="url"
                                    rel="author">
                                    Bürgerstiftung Kreis Ravesnburg
                                </a>
                            </span>
                        </span>
                    </small>

                    <p>
                        <small>
                            <span itemProp="alternativeHeadline">
                                {this.state.header}
                            </span>
                            -
                            <span itemProp="description">
                                {this.state.description}
                            </span>
                        </small>
                    </p>
                </Grid.Row>
            </Grid>
        );
    }
}

export default BlogArticlePage