import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Card from "semantic-ui-react/dist/commonjs/views/Card";

class CardDateComponent extends React.Component {

    render() {
        const {title, text, date, color} = this.props;
        if (!title || !text || !date) {
            console.log('Src is not acceptable');
            return null;
        }

        let day = moment(date, 'YYYY/MM/DD').date();
        let month = 1 + moment(date, 'YYYY/MM/DD').month();
        let year = moment(date, 'YYYY/MM/DD').year();

        let cssClass = "date-blue";
        if (color) {
            if (color === "orange") {
                cssClass = "date-orange"
            } else if (color === "light-blue") {
                cssClass = "date-light-blue"
            } else if (color === "green") {
                cssClass = "date-green"
            }
        }

        switch (month) {
            case 1:
                month = "JAN";
                break;
            case 2:
                month = "FEB";
                break;
            case 3:
                month = "MRZ";
                break;
            case 4:
                month = "APR";
                break;
            case 5:
                month = "MAI";
                break;
            case 6:
                month = "JUN";
                break;
            case 7:
                month = "JUL";
                break;
            case 8:
                month = "AUG";
                break;
            case 9:
                month = "SEP";
                break;
            case 10:
                month = "OKT";
                break;
            case 11:
                month = "NOV";
                break;
            case 12:
                month = "DEZ";
                break;
            default:
                break;

        }


        return (
            <Grid.Row computer={16} tablet={16} mobile={16} centered className={"icon-card-container"}>
                <Grid.Column computer={12} tablet={14} mobile={16}>
                    <Card fluid className={"card-shadow stretched-card"}>
                        <Card.Content>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={3}>
                                        <p className={cssClass}>
                                            <h2>{day >= 10 ? day : "0" + day}</h2>
                                            <text>{month + " " + year}</text>
                                        </p>
                                    </Grid.Column>
                                    <Grid.Column width={13} textAlign={"left"} verticalAlign={"middle"}>
                                        <h3>{title}</h3>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign={"left"}>
                                        <p className={"text-block"}>{text}</p>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid.Row>
        )
    }
}

CardDateComponent.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    date: PropTypes.string,
    color: PropTypes.string
};

CardDateComponent.defaultProps = {
    title: null,
    text: null,
    date: new Date(),
    color: ""
};

export default CardDateComponent